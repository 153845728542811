import { useEffect, useState } from 'react';
import s from './editAdvertiserCompany.module.scss';
import { advertiserApi, useAdvertiserChangeAnnouncementStatusMutation, useAdvertiserGetCompaniesQuery, useAdvertiserGetPricesQuery } from '../../../store/apis/advertiserApi';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoaderContent from '../../../components/LoaderContent';
import { ReactComponent as SuccessIcon } from '../../../../utils/icons/success-verification.svg'
import { ReactComponent as RejectIcon } from '../../../../utils/icons/close-classik-icon-violet.svg'
import AlertModal from '../../../components/AlertModal';
import { ConfirmModal } from '../../../components/ConfirmModal/ConfirmModal';
import { base64ToBlob } from '../../../../utils/helpers/base64ToBlob';
import baner1 from '../../../../utils/assets/images/advertiser_baner1.webp'
import baner2 from '../../../../utils/assets/images/advertiser_baner2.webp'
import baner3 from '../../../../utils/assets/images/advertiser_baner3.webp'
import baner4 from '../../../../utils/assets/images/advertiser_baner4.webp'
import baner5 from '../../../../utils/assets/images/advertiser_baner5.webp'
import baner6 from '../../../../utils/assets/images/advertiser_baner6.webp'
import { useTranslation } from 'react-i18next';
import { costСalculation } from '../../../../utils/costCalculation'
import { VerificationMessages } from '../../../components/verificationMessagesModal/verificationMessagesModal';
import { useGetWarningsMutation } from '../../../store/apis/usersApi';
import { useDispatch } from 'react-redux';
import i18next from "i18next";


export const EditAdvertiserCompany = () => {

    const {i18n} = useTranslation()
    const [lang, setLang] = useState(i18n.language)
    const {t} = useTranslation()
    const statuses = [
        {id: 0, title: t('u_om'), class: 'moderation'},
        {id: 1, title: t('active'), class: 'active'},
        {id: 2, title: t('rejected'), class: 'rejected'},
        {id: 3, title: t('waiting_payment'), class: 'payment'},
        {id: 4, title: t('suspended'), class: 'suspended'}
    ]

    // ### State
    const [status, setStatus] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [messagesModal, setMessagesModal] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [prices, setPrices] = useState({mainPrice: "---", internalPrice: "---"})

    const [params] = useSearchParams()
    const companyId = params.get('id')
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const {data: company, isFetching: isCompaniesLoading} = useAdvertiserGetCompaniesQuery({id:companyId})
    const {data: pricesData, isLoading: isPricesLoading} = useAdvertiserGetPricesQuery()
    const [changeStatus, {isLoading: isChangeStatusLoading}] = useAdvertiserChangeAnnouncementStatusMutation()
    const [getWarning, {isLoading: isGetMenuWarnings}] = useGetWarningsMutation()
    
    // ### Effects
    useEffect(()=>{
        if(pricesData && company){
            const pricesState = costСalculation({data: company.companies?.[0], prices: pricesData})
            if(pricesState){
                setPrices(pricesState)
            }
        }
    },[pricesData, company]);

    useEffect(()=>{
        if(company?.companies[0]){
            setStatus(company.companies[0].status)
        }
    },[company]);

    // ### Functions

    async function handleSubmit(status, message){
        const response = await changeStatus({
            id: parseInt(companyId),
            status,
            message: message
        })
        if(response.error){
            setErrorMessage(response.error.data?.message)
            return
        }
        await getWarning()
        dispatch(advertiserApi.util.resetApiState())
    }

    // ### Views
    const categoryListView = company?.companies[0]?.categories?.map(el => (
        <div className={s.list_item}>
            <span className={s.title}>{el.name}</span> 
        </div>
    ))
    console.log("i18next", Object.keys(i18next.options.resources || {}));
    return(
        <div className="terms">
            <h1 className='terms-title'>{company?.companies[0]?.companyName}</h1 >
            {
				errorMessage
				&&
				<AlertModal
					content={ errorMessage }
					setErrorMessage={ setErrorMessage }
				/>
			}
            <div className={`${s.row} ${s.header}`}>
                    <div className={`${s.header} ${s.box_shadow}`}>
                        <div className={s.input_wrapper}>
                            <span className={s.title}>{t('ad_company_name')}</span>
                            <span className={s.input}>{company?.companies[0]?.companyName}</span>
                        </div>
                        <span className={s.title}>{t('ad_company_image')}</span>
                        <div className={s.img_wrapper}>
                            <img src={company?.companies[0]?.companyImage ? URL.createObjectURL(base64ToBlob(company?.companies[0]?.companyImage?.file, company?.companies[0]?.companyImage?.mimetype)) : null} alt="company_image" />
                        </div>
                        <div className={s.footer}>
                            {(status === 0 || status === 2) ? 
                                <div className={`${s.success_button}`} onClick={()=>setConfirmModal(company?.companies[0].companyName)}>
                                    <SuccessIcon/>
                                    <span>{t('ad_confirm_company')}</span>
                                </div> : null
                            }
                            {status !== 2 ? 
                            <div className={`${s.success_button} ${s.reject_button}`} onClick={()=>setMessagesModal({type: 4, status: 2})}>
                                <RejectIcon/>
                                <span>{t('reject')}</span>
                            </div> : null
                            }
                            <button
                                type='button'
                                className='template-content_buttons-close btn btn-primary me-3'
                                onClick={()=>{navigate(-1)}}
                                style={{margin: 0}}
                            >
                                {t('back')}
                            </button >
                        </div>
                        <div className={`${s.moderation_status} ${s[(statuses?.find(el => el.id === company?.companies?.[0]?.status)?.class)]}`}>
                            {(statuses?.find(el => el.id === company?.companies?.[0]?.status)?.title)}
                        </div>
                    </div>
                </div>

                <div className={`${s.banners_wrapper} ${s.box_shadow}`}>
                    <div className={s.langs_buttons}>
                        {
                            Object.keys(i18next.options.resources || {})?.map((ln, index) => (
                                <span 
                                    className={`${s.lang_item} ${lang === ln ? s.active : ''}`} 
                                    key={index}
                                    onClick={() => setLang(ln)}
                                >
                                    {ln}
                                </span>
                            ))
                        }
                    </div>
                    <h3>{t('as_banners_text')}</h3>
                    <div className={s.banners}>
                        <div className={`${s.banner} ${s.box_shadow}`}>
                            <div className={s.image}>
                                <div className={s.img_wrapper}>
                                    <img src={company?.companies[0]?.banners[1]?.[lang] ? URL.createObjectURL(base64ToBlob(company?.companies[0]?.banners[1]?.[lang]?.file, company?.companies[0]?.banners[1]?.[lang]?.mimetype)) : baner1} alt="" />
                                </div>
                            </div>
                            <div className={s.center}>
                                <b>{t('banner1_title')}</b>
                                <span>{t('banner1_message')}</span>
                            </div>
                        </div>

                        <div className={`${s.banner} ${s.box_shadow}`}>
                            <div className={s.image}>
                                <div className={s.img_wrapper}>
                                    <img src={company?.companies[0]?.banners[2]?.[lang] ? URL.createObjectURL(base64ToBlob(company?.companies[0]?.banners[2]?.[lang]?.file, company?.companies[0]?.banners[2]?.[lang]?.mimetype)) : baner2} alt="" />
                                </div>
                            </div>
                            <div className={s.center}>
                                <b>{t('banner2_title')}</b>
                                <span>{t('banner2_message')}</span>
                            </div>
                        </div>

                        <div className={`${s.banner} ${s.box_shadow}`}>
                            <div className={s.image}>
                                <div className={s.img_wrapper}>
                                    <img src={company?.companies[0]?.banners[3]?.[lang] ? URL.createObjectURL(base64ToBlob(company?.companies[0]?.banners[3]?.[lang]?.file, company?.companies[0]?.banners[3]?.[lang]?.mimetype)) : baner3} alt="" />
                                </div>
                            </div>
                            <div className={s.center}>
                                <b>{t('banner3_title')}</b>
                                <span>{t('banner3_message')}</span>
                            </div>
                        </div>

                        <div className={`${s.banner} ${s.box_shadow}`}>
                            <div className={s.image}>
                                <div className={s.img_wrapper}>
                                    <img src={company?.companies[0]?.banners[4]?.[lang] ? URL.createObjectURL(base64ToBlob(company?.companies[0]?.banners[4]?.[lang]?.file, company?.companies[0]?.banners[4]?.[lang]?.mimetype)) : baner4} alt="" />
                                </div>
                            </div>
                            <div className={s.center}>
                                <b>{t('banner4_title')}</b>
                                <span>{t('banner4_message')}</span>
                            </div>
                        </div>
                        {/* ### Mobile */}
                        <div className={`${s.banner} ${s.box_shadow}`}>
                            <div className={s.image}>
                                <div className={s.img_wrapper}>
                                    <img src={company?.companies[0]?.banners[5]?.[lang] ? URL.createObjectURL(base64ToBlob(company?.companies[0]?.banners[5]?.[lang]?.file, company?.companies[0]?.banners[5]?.[lang]?.mimetype)) : baner5} alt="" />
                                </div>
                            </div>
                            <div className={s.center}>
                                <b>{t('banner5_title')}</b>
                                <span>{t('banner5_message')}</span>
                            </div>
                        </div>

                        <div className={`${s.banner} ${s.box_shadow}`}>
                            <div className={s.image}>
                                <div className={s.img_wrapper}>
                                    <img src={company?.companies[0]?.banners[6]?.[lang] ? URL.createObjectURL(base64ToBlob(company?.companies[0]?.banners[6]?.[lang]?.file, company?.companies[0]?.banners[6]?.[lang]?.mimetype)) : baner6} alt="" />
                                </div>
                            </div>
                            <div className={s.center}>
                                <b>{t('banner6_title')}</b>
                                <span>{t('banner6_message')}</span>
                            </div>
                        </div>
                        
                    </div>

                    <h3>{t('ad_text1')}</h3>
                    <div className={s.input_row}>
                        <div className={`${s.select_wrapper} select_wrapper`}>
                            <span className={s.title}>{t('ad_change_categories')}</span>
                            <div className={s.input_list_wrapper}>
                                <div className={s.items}>
                                    {categoryListView || '---'}
                                </div>
                            </div>
                        </div>
                        <div className={`${s.select_wrapper}`}>
                            <span className={s.title}>{t('ad_gender')}</span>
                            <div className={s.checkboxes_wrapper}>
                                <div className={s.checkbox_wrapper}>
                                    <input type="checkbox"  checked={company?.companies[0]?.gender?.male}/>
                                    <span className={s.label}>{t('ad_male')}</span>
                                </div>
                                <div className={s.checkbox_wrapper}>
                                    <input type="checkbox"  checked={company?.companies[0]?.gender?.female}/>
                                    <span className={s.label}>{t('ad_female')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={s.input_row}>
                        <div className={`${s.select_wrapper} select_wrapper`}>
                            <span className={s.title}>{t('ad_age')}</span>
                            <div className={s.input_list_wrapper}>
                                <div className={s.items}>
                                    {
                                        company?.companies[0]?.age?.map((el, index) => (
                                            <div key={index} className={s.list_item}>
                                                <span className={s.title}>{el.title}</span> 
                                            </div>
                                        )) || '---'
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={`${s.select_wrapper}`}>
                            <span className={s.title}>{t('ad_language')}</span>
                            <div className={s.input_list_wrapper}>
                                <div className={s.items}>
                                    {
                                        company?.companies[0]?.langs?.map((el, index) => (
                                            <div key={index} className={s.list_item}>
                                                <span className={s.title}>{el.title}</span> 
                                            </div>
                                        )) || '---'
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={s.input_row}>
                        <div className={`${s.select_wrapper}`}>
                            <span className={s.title}>{t('ad_region')}</span>
                            <div className={s.input_list_wrapper}>
                                <div className={s.items}>
                                    {
                                        company?.companies[0]?.regions?.map((el, index) => (
                                            <div key={index} className={s.list_item}>
                                                <span className={s.title}>{el.title}</span> 
                                            </div>
                                        )) || '---'
                                    }
                                </div>
                            </div> 
                        </div>
                        <div className={`${s.select_wrapper}`}>
                            <span className={s.title}>{t('ad_province')}</span>
                            <div className={s.input_list_wrapper}>
                                <div className={s.items}>
                                    {
                                        company?.companies[0]?.provinces?.map((el, index) => (
                                            <div key={index} className={s.list_item}>
                                                <span className={s.title}>{el.title}</span> 
                                            </div>
                                        )) || "---"
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={s.input_row}>
                        <div className={`${s.select_wrapper}`}>
                            <span className={s.title}>{t('ad_date')}</span>
                            <div className={s.input_wrapper}>
                                <span className={s.input}>
                                    {`
                                    ${company?.companies[0]?.dateRange?.from ? new Date(parseInt(company?.companies[0]?.dateRange?.from)).toLocaleDateString() : ''} 
                                    - 
                                    ${company?.companies[0]?.dateRange?.to ? new Date(parseInt(company?.companies[0]?.dateRange?.to)).toLocaleDateString() : ''}
                                    `}
                                </span>
                            </div>
                        </div>
                        <div className={`${s.select_wrapper}`}>
                            <span className={s.title}>{t('ad_views_count')}</span>
                            <div className={s.input_wrapper}>
                                <span className={s.input}>{company?.companies[0]?.countShow}</span>
                            </div>
                        </div>
                    </div>

                    <div className={s.input_row}>
                        <div className={`${s.select_wrapper}`}>
                            <span className={s.title}>{t('ad_link')}</span>
                            <div className={s.input_wrapper}>
                                <span className={s.input}>{company?.companies[0]?.link}</span>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className={s.price_row}>
                        <div className={`${s.left} ${s.price_block}`}>
                            <div className={s.price_item}>
                                <p className={s.title}>{t('ad_internal_price')} <b>{t('ad_main_price2')}</b></p>
                                <p className={s.price}>{prices.mainPrice} THB / 1000 {t('views')}</p>
                            </div>
                        </div>
                        <div className={`${s.price_block}`}>
                            <div className={s.price_item}>
                                <p className={s.title}>{t('ad_internal_price')} <b>{t('ad_internal_price2')}</b></p>
                                <p className={s.price}>{prices.internalPrice} THB / 1000 {t('views')}</p>
                            </div>
                        </div>
                    </div>
                    
                </div>
                {(isCompaniesLoading || isChangeStatusLoading || isPricesLoading || isGetMenuWarnings) && <LoaderContent />}

            {confirmModal? <ConfirmModal title={t('moderation')} text={`${t('ad_confirm_moderation')} ${confirmModal}?`} close={()=>setConfirmModal(false)} success={()=>{handleSubmit(1)}} /> : null}
            {messagesModal ? <VerificationMessages type={messagesModal.type} close={()=>setMessagesModal(false)} confirm={(type, message)=>{handleSubmit(type, message.message)}}/> : null}
        </div>
    )
}