import { useNavigate } from 'react-router-dom';
import s from './advertiserCompanyItem.module.scss';
import { base64ToBlob } from '../../../../utils/helpers/base64ToBlob';
import { InfoIcon } from '../../../components/InfoIcon/InfoIcon';
import { useTranslation } from 'react-i18next';

const statuses = {
    0 : "Moderation",
    1 : "Active",
    2 : "Rejected",
    3 : "Waiting for payment",
    4 : "Suspended"
}

export const AdvertiserCompanyItem = ({company}) => {

    // ### State
    const {i18n} = useTranslation()
    const navigate = useNavigate()
    // ### Effects

    // ### Functions

    // ### Views
    
    return(
        <tr className={s.company} onClick={()=>{navigate(`/advertiser/company/view?id=${company.id}`)}}>
            <td className={s.company_td}>
                <div className={`${s.company_wrapper}`}>
                    <div className={s.company_image}>
                        <img src={company.companyImage ? URL.createObjectURL(base64ToBlob(company.companyImage?.file, company.companyImage?.mimetype)) : null} alt="company_image" />
                    </div>
                    <div className={s.company_content}>
                        <span className={s.company_name}>{company.companyName}</span>
                        <span className={s.company_region}>{company.regions?.map(el => el.title)}</span>
                    </div>
                </div>
            </td>
            <td><div className={s.td_wrapper}>{new Date(company.created).toLocaleDateString()}</div></td>
            <td><div className={s.td_wrapper}>{company.statistics?.filter(el => el.action === "view")?.length || 0}</div></td>
            <td><div className={s.td_wrapper}>{company.statistics?.filter(el => el.action === "click")?.length || 0}</div></td>
            <td><div className={s.td_wrapper}><span className={company.status === 0? s.status_moderation : company.status === 1? s.status_active : company.status === 2? s.status_reject : company.status === 3? s.status_payment : company.status === 4? s.status_suspended : ""}>{statuses[company.status]}</span></div></td>
            <td><div className={s.td_wrapper}><div className={s.price_wrapper}><span className={s.amount}>{`${company.pricePerShow?.mainPrice}/${company.pricePerShow.internalPrice}`}</span><InfoIcon text="Main page / Internal page"/></div></div></td>
        </tr>
    )
}